import Vue from 'vue'
import VueRouter from 'vue-router'
const Dispatch = () => import('@/views/distribution/Dispatch')
const DispatchV2 = () => import('@/views/distribution/DispatchV2')
const BaseDispatch = () => import('@/views/distribution/BaseDispatch')
const Home = () => import('@/views/Home')
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/distribution/link/:id/domain/:domainId',
    name: 'distribution',
    component: Dispatch
  },
  {
    path: '/distribution-base',
    name: 'distribution-base',
    component: BaseDispatch
  },
  {
    path: '/distributionV2',
    name: 'distributionv2',
    component: DispatchV2
  }
]

const router = new VueRouter({
  mode: 'history',
  routes: routes
})

export default router
