<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'App',
    components: {},
    created() {
        new this.$vconsole()
        // 一个没搞明白的bug， 华为手机，只有引入了vconsole才能使用weixinjsbridge
        // 隐藏 vconsole 开关按钮
        let switchBtns = document.getElementsByClassName('vc-switch')
        if (switchBtns && switchBtns.length > 0) {
            switchBtns[0].style = 'background: transparent !important;color: #ffffff !important;box-shadow: none !important;pointer-events: none;'
        }
    },
	mounted() {
	}
}
</script>

<style>
#__vconsole {
    .vc-switch {
        background: transparent !important;
        color: #ffffff !important;
        box-shadow: none !important;
        pointer-events: none;
    }
}
</style>
